import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, ternary } from '../../Utils/javascript'
import {
  ADD_EDIT_PROJECT_ACCESS_FAILED,
  ADD_EDIT_PROJECT_ACCESS_REQUESTED,
  ADD_EDIT_PROJECT_ACCESS_SUCCESS,
  CLEAR_CLIENT_ACCESS_DATA,
  DELETE_CLIENT_ACCESS_PROJECT_FAILED,
  DELETE_CLIENT_ACCESS_PROJECT_REQUESTED,
  DELETE_CLIENT_ACCESS_PROJECT_SUCCESS,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS,
  FETCH_CLIENT_COMPANY_LIST_FAILED,
  FETCH_CLIENT_COMPANY_LIST_REQUESTED,
  FETCH_CLIENT_COMPANY_LIST_SUCCESS,
} from '../constants/clientAccess'
import { updateSaveStatusAction } from './confirmation'

const fetchClientCompanyListRequested = () => ({
  type: FETCH_CLIENT_COMPANY_LIST_REQUESTED,
})
const fetchClientCompanyListSuccess = (payload) => ({
  type: FETCH_CLIENT_COMPANY_LIST_SUCCESS,
  payload,
})
const fetchClientCompanyListFailed = (payload) => ({
  type: FETCH_CLIENT_COMPANY_LIST_FAILED,
  payload,
})

export const getClientCompany = () => async (dispatch) => {
  dispatch(fetchClientCompanyListRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.clientCompany,
    method: method.get,
  })

  if (data) {
    dispatch(fetchClientCompanyListSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchClientCompanyListFailed(error.response.data))
  }
}

const fetchClientAccessProjectListRequested = () => ({
  type: FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED,
})
const fetchClientAccessProjectListSuccess = (payload) => ({
  type: FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS,
  payload,
})
const fetchClientAccessProjectListFailed = (payload) => ({
  type: FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED,
  payload,
})

export const getClientAccessProjectList = (companyId) => async (dispatch) => {
  dispatch(fetchClientAccessProjectListRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.clientProjects}/${companyId}`,
    method: method.get,
  })

  if (data) {
    dispatch(fetchClientAccessProjectListSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchClientAccessProjectListFailed(error.response.data))
  }
}

const fetchAssociatedClientsWithProjectsRequested = () => ({
  type: FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED,
})
const fetchAssociatedClientsWithProjectsSuccess = (payload) => ({
  type: FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS,
  payload,
})
const fetchAssociatedClientsWithProjectsFailed = (payload) => ({
  type: FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED,
  payload,
})

export const getAssociatedClientsWithProjects =
  (projectId) => async (dispatch) => {
    dispatch(fetchAssociatedClientsWithProjectsRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.clientProjectAccess}?project_id=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchAssociatedClientsWithProjectsSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchAssociatedClientsWithProjectsFailed(error.response.data))
    }
  }

// Update construction summary
const addEditProjectAccessRequested = () => ({
  type: ADD_EDIT_PROJECT_ACCESS_REQUESTED,
})
const addEditProjectAccessSuccess = () => ({
  type: ADD_EDIT_PROJECT_ACCESS_SUCCESS,
})
const addEditProjectAccessFailed = (payload) => ({
  type: ADD_EDIT_PROJECT_ACCESS_FAILED,
  payload,
})

export const addEditProjectAccess =
  (payload, clientAccessId, editProjectAccess) => async (dispatch) => {
    dispatch(addEditProjectAccessRequested())
    const { data, error } = await api({
      endPoint: editProjectAccess
        ? `${apiEndPoints.clientProjectAccess}update/${clientAccessId}/`
        : apiEndPoints.clientProjectAccess,
      method: editProjectAccess ? method.patch : method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: `Project Access ${ternary(
        editProjectAccess,
        'Updated',
        'Saved',
      )} successfully!`,
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(getClientAccessProjectList(payload?.company))
      dispatch(addEditProjectAccessSuccess())
      return
    }
    if (error) {
      dispatch(addEditProjectAccessFailed(error.response.data))
    }
  }

const deleteClientAccessProjectRequested = () => ({
  type: DELETE_CLIENT_ACCESS_PROJECT_REQUESTED,
})
const deleteClientAccessProjectSuccess = (payload) => ({
  type: DELETE_CLIENT_ACCESS_PROJECT_SUCCESS,
  payload,
})
const deleteClientAccessProjectFailed = (payload) => ({
  type: DELETE_CLIENT_ACCESS_PROJECT_FAILED,
  payload,
})

export const clearClientAccessData = () => ({
  type: CLEAR_CLIENT_ACCESS_DATA,
})

export const clientAccessProjectDelete =
  ({ payload, companyId }) =>
  async (dispatch, getState) => {
    dispatch(deleteClientAccessProjectRequested())
    const { projectAccessList } = getState().clientAccess

    const { status, error } = await api({
      endPoint: `${apiEndPoints.clientProjectAccess}delete/${companyId}/`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    const filterData = projectAccessList?.filter(
      (item) => !checkIncludes(item.id, payload?.projects),
    )
    if (status) {
      dispatch(deleteClientAccessProjectSuccess(filterData))
    }
    if (error) {
      dispatch(deleteClientAccessProjectFailed(error.response.data))
    }
  }
