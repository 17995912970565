import { TableCell, TableRow } from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import {
  useExpandedRowStyles,
  useStyles,
} from '../../../Styles/reportTierSettings.style'
import { reportSections } from '../../../Description/reportTierSettings.description'
import {
  checkUndefined,
  equal,
  notNull,
  ternary,
} from '../../../Utils/javascript'
import { fetchReportTierSettings } from '../../../Redux/actions/constructionMonitoringReport'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'

const ExpandedRow = memo(({ name, data, expandableRowClass }) => {
  const { disabledFields, ...settings } = data
  const classes = useExpandedRowStyles()
  const renderTableCell = ({ key, value }) =>
    ternary(
      equal(key, 'reportSection'),
      <TableCell key={key}>{value}</TableCell>,
      <TableCell key={key}>
        {!checkUndefined(value) && (
          <DKTCheckbox name={name} checked={value} disabled />
        )}
      </TableCell>,
    )
  return (
    <TableRow key={name} className={expandableRowClass}>
      <TableCell className={classes.hideOnPrint}></TableCell>
      {[
        'reportSection',
        'firstTier',
        'secondTier',
        'thirdTier',
        'includeInAppendix',
      ].map((key) => {
        const value = settings[key]
        return ternary(
          equal(key, 'includeInAppendix'),
          ternary(
            notNull(value) && !checkUndefined(value),
            renderTableCell({ key, value }),
            null,
          ),
          renderTableCell({ key, value }),
        )
      })}
    </TableRow>
  )
})

const ReportTierTableModal = ({ open, handleOpenModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { reportTierSettings, isFetchingReportTierSettings } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  useEffect(() => {
    dispatch(fetchReportTierSettings())
  }, [dispatch])

  const customBodyRender = useCallback((value, tableMeta) => {
    const [reportSection] = tableMeta.rowData
    const name = `${reportSection}.tiers`

    return (
      <DKTCheckbox
        name={name}
        disabled
        value={tableMeta.columnData.label}
        checked={value}
      />
    )
  }, [])

  const columns = [
    {
      name: 'reportSection',
      label: 'Report Section',
      options: {
        filter: true,
      },
    },
    {
      name: 'firstTier',
      label: 'Tier 01',
      options: {
        filter: true,
        customBodyRender,
      },
    },
    {
      name: 'secondTier',
      label: 'Tier 02',
      options: {
        filter: false,
        customBodyRender,
      },
    },
    {
      name: 'thirdTier',
      label: 'Tier 03',
      options: {
        filter: true,
        customBodyRender,
      },
    },
    {
      name: 'includeInAppendix',
      label: 'Include in Appendix',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subSections',
      options: {
        display: false,
      },
    },
  ]

  const options = useMemo(
    () => ({
      filter: false,
      filterType: 'dropdown',
      pagination: false,
      viewColumns: false,
      responsive: 'standard',
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      rowsExpanded: reportTierSettings.map((row, index) => index),
      selectableRows: 'none',
      isRowExpandable: (dataIndex) =>
        !!Object.values(reportSections)[dataIndex]?.subSections,
      renderExpandableRow: (rowData) => {
        const [reportSection] = rowData
        const subSections = rowData[5]
        return subSections?.map((section) => {
          const name = `${reportSection}.${section.name}.tiers`
          return (
            <ExpandedRow
              key={section.reportSection}
              name={name}
              data={section}
              expandableRowClass={classes.expandableRow}
            />
          )
        })
      },
    }),
    [classes.expandableRow],
  )

  return (
    <DKTDialog
      open={open}
      handleClose={() => handleOpenModal(false)}
      title="Report Tier Setting"
      maxWidth="xl"
      sx={{
        '& .MuiPaper-root': {
          boxSizing: 'border-box',
          overflowY: 'hidden',
          '& .MuiDialogContent-root': {
            '& .MuiPaper-root': {
              boxSizing: 'border-box',
              paddingTop: '0px !important',
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'auto',
            },
          },
        },
      }}
    >
      {isFetchingReportTierSettings ? (
        <DKTCircularProgress />
      ) : (
        <DKTMuiDataTable
          data={reportTierSettings}
          columns={columns}
          className={classes.reportTierTable}
          options={options}
        />
      )}
    </DKTDialog>
  )
}

export default memo(ReportTierTableModal)
