import React from 'react'
import { Grid, Typography } from '@mui/material'
import { formFields } from '../../../Description/projectSetting.description'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'

const ReportTier = ({
  renderFormFields,
  isUnsavedData,
  handleSetReportTier,
  handleOpenModal,
}) => (
  <>
    <Grid container spacing={2} mb={2} alignItems="end">
      <Grid item xs={4}>
        {renderFormFields(formFields.reportTier)}
      </Grid>
      <Grid item xs={8}>
        <Typography
          mb={0.8}
          style={{ cursor: 'pointer', color: 'rgb(21, 100, 255)' }}
          onClick={handleOpenModal}
        >
          View Tier Settings
        </Typography>
      </Grid>
    </Grid>
    <DKTReactRouterPrompt
      isDirty={isUnsavedData}
      onSave={handleSetReportTier}
    />
  </>
)

export default ReportTier
