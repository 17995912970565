import { Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'

import AddIcon from '@mui/icons-material/Add'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useParams } from 'react-router-dom'
import { formFields } from '../../../Description/createContact.description'
import useForm from '../../../Hooks/useForm'
import { getProjectChoices } from '../../../Redux/actions/choices'

import {
  saveContactType,
  searchCompany,
  updateCompanyName,
} from '../../../Redux/actions/projectDirectory'
import { getStateList } from '../../../Redux/actions/states'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import { useStyles } from '../../../Styles/createContact.style'
import {
  entries,
  equal,
  isEmptyString,
  keys,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { editCompanyNameFormFields } from '../../../Description/projectDirectory.description'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTForm from '../../../Shared/DKTForm'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'

const SelectMenuButton = ({ children, onClick, companyName, ...props }) => (
  <components.MenuList {...props}>
    {children}
    <button
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        marginTop: '10px',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        border: '0.5px solid lightgrey',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
        width: '100%',
        ...ternary(companyName, {}, { color: 'gray' }),
      }}
      type="button"
      onClick={onClick}
    >
      <AddIcon />
      Add New Company
    </button>
  </components.MenuList>
)

const CustomOption = ({ children, handleOpenEditOptionDialog, ...rest }) => {
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const contactTypeLabel = choices?.contactType?.find(({ value }) =>
    equal(value, rest?.data?.contactType),
  )?.label

  return (
    <components.Option {...rest}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Stack direction="row" spacing={1} alignItems="baseline">
          <Typography>{children}</Typography>
          <Stack direction="row" ml={1} alignItems="baseline" spacing={0.5}>
            <Typography fontSize={12} lineHeight="14px">
              {rest?.data?.city}
            </Typography>
            <Typography fontSize={12} lineHeight="14px">
              {rest?.data?.state}
            </Typography>
            <Typography fontSize={12} lineHeight="14px">
              ({contactTypeLabel})
            </Typography>
          </Stack>
        </Stack>

        <IconButton
          sx={{
            height: 25,
            padding: 0,
            backgroundColor: ternary(rest.isSelected, 'white', 'inherit'),
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          color="primary"
          onClick={() => handleOpenEditOptionDialog(rest?.data)}
        >
          <ModeEditIcon sx={{ height: 15 }} />
        </IconButton>
      </Stack>
    </components.Option>
  )
}

const CustomDropdownIndicator = ({
  children,
  handleToggleMenuOption,
  ...rest
}) => (
  <components.DropdownIndicator {...rest}>
    <ExpandMoreRoundedIcon onClick={handleToggleMenuOption} />
  </components.DropdownIndicator>
)

const CreateContact = ({
  open = false,
  contactTypeToEdit,
  onClose,
  hasOnlyViewPermission,
  onSave = () => {},
  isAddNewContactType = false,
}) => {
  const classes = useStyles()
  const ref = useRef()
  const [contactType, setContactType] = useState({})
  const [companyName, setCompanyName] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [isOpenEditDialog, setOpenEditDialog] = useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const {
    values,
    setValues,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setErrors,
  } = useForm({ ...formFields, ...editCompanyNameFormFields })

  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const { state } = useSelector(({ states }) => states)
  const {
    isSearchingCompany,
    searchResults,
    isSavingContactType,
    saveContactTypeError,
    companyNameList,
  } = useSelector(({ projectDirectory }) => projectDirectory)
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()

  const isUpdate = companyNameList?.findIndex(
    ({ id }) => +id === +values.companyName,
  )

  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: 'contactType' }))
    dispatch(getStateList())
  }, [dispatch])

  useEffect(() => {
    dispatch(searchCompany('', 'isPopulate'))
  }, [dispatch])

  useEffect(() => {
    setCompanyList(searchResults)
  }, [searchResults])

  // fill form based on company name
  useEffect(() => {
    const dataClone = companyNameList?.find(
      (res) => +res.id === +values.companyName,
    )
    if (values?.companyName) {
      setValues(
        dataClone
          ? { ...values, ...dataClone, companyName: dataClone?.id?.toString() }
          : { ...values },
      )
    } else {
      resetForm()
    }
  }, [values?.companyName])

  useEffect(() => {
    const contactTypeObj = companyList?.find((res) =>
      equal(`${res?.value}`, values.companyName),
    )
    setContactType(contactTypeObj)
  }, [dispatch, searchResults, values.companyName, open])
  useEffect(() => {
    const companyNameClone = companyList?.find(
      (res) => +res.value === contactTypeToEdit?.id,
    )
    resetForm()
    if (contactTypeToEdit) {
      entries(contactTypeToEdit).forEach(([fieldName, fieldValue]) => {
        setFieldValue(
          fieldName,
          ternary(
            equal(fieldName, 'companyName'),
            companyNameClone?.value,
            fieldValue,
          ),
        )
      })
    }
    if (!open) setContactType({})
  }, [contactTypeToEdit, open])

  // Set errors on save
  useEffect(() => {
    if (saveContactTypeError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...saveContactTypeError,
      }))
  }, [saveContactTypeError, setErrors])

  // set isUnsavedData as true
  useEffect(() => {
    if (keys(contactTypeToEdit).length) {
      const companyNameClone = companyList?.find(
        (res) => +res.value === +contactTypeToEdit.id,
      )
      const res = []
      keys(formFields)?.forEach((contact) => {
        const prevValue = equal(contact, 'companyName')
          ? `${companyNameClone?.value}`
          : contactTypeToEdit[contact]
        const currentValue = values[contact]
        res.push(
          String(isEmptyString(prevValue) ? '' : prevValue) !==
            String(isEmptyString(currentValue) ? '' : currentValue),
        )
      })
      setIsUnSavedChanges(res?.flat()?.some((data) => data === true))
    }
  }, [contactTypeToEdit, values])

  useEffect(() => {
    if (open && !contactTypeToEdit && keys(values).length) {
      setIsUnSavedChanges(true)
    }
  }, [values])

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      dispatch(
        saveContactType(
          {
            ...values,
            companyName:
              contactType?.label ?? companyNameList?.[isUpdate]?.companyName,
          },
          contactTypeToEdit?.id || ~isUpdate ? +values.companyName : null,
          projectId,
          {
            onSuccess: () => {
              onSave()
              onClose()
            },
          },
        ),
      )
      setIsUnSavedChanges(false)
    }
  }

  const handelCloseEditDialog = () => {
    setOpenEditDialog(false)
    setCompanyName('')
    setFieldValue('isCompanyNameUpdateWarning', false)
  }

  const handleToggleMenuOption = () => {
    setMenuIsOpen(!menuIsOpen)
    document.activeElement?.blur()
  }

  const actions = (
    <DKTButton
      onClick={handleSave}
      disabled={
        hasOnlyViewPermission || isSavingContactType || isSearchingCompany
      }
      sx={{ marginLeft: 'auto' }}
    >
      {ternary(
        isAddNewContactType && ~isUpdate && isSavingContactType,
        'Saving & Updating...',
        ternary(
          isAddNewContactType && ~isUpdate,
          'Save & Update',
          ternary(
            isSavingContactType,
            ternary(~isUpdate, 'Updating...', 'Saving...'),
            ternary(~isUpdate, 'Update', 'Save'),
          ),
        ),
      )}
    </DKTButton>
  )

  const stateAbbreviations = useMemo(
    () => state?.map(({ value }) => ({ label: value, value })) || [],
    [state],
  )

  const addNewCompany = () => {
    if (companyName) {
      setCompanyList((prevCompanies) => [
        ...prevCompanies,
        { label: companyName, value: new Date().getTime().toString() },
      ])
    }
  }

  const handleOpenEditOptionDialog = (data) => {
    setSelectedCompany(data)
    setFieldValue('editCompanyName', data.label)
    setOpenEditDialog(true)
  }

  const handleEditCompanyName = () => {
    const cloneCompanyList = [...companyList]
    const companyId = selectedCompany?.value
    const companyIndex = cloneCompanyList.findIndex((company) =>
      equal(company?.value, companyId),
    )
    cloneCompanyList?.splice(companyIndex, 1, {
      label: values?.editCompanyName,
      value: companyId,
    })
    setCompanyList(cloneCompanyList)
    setContactType({ label: values?.editCompanyName, value: companyId })
    setMenuIsOpen(false)
    dispatch(
      updateCompanyName({
        payload: { companyName: values?.editCompanyName },
        companyId,
      }),
    )
    handelCloseEditDialog()
  }

  const handleOpenMenu = () => {
    setMenuIsOpen(true)
  }
  const handleCloseMenu = () => {
    setMenuIsOpen(false)
  }
  const handelInputChange = (data) => {
    setFieldValue('companyName', data?.value)
    setMenuIsOpen(false)
    document.activeElement?.blur()
  }

  const customFormControlFields = ({ name, formControl }) => ({
    options: ternary(
      equal(name, 'contactType'),
      choices.contactType || [],
      ternary(equal(name, 'state'), stateAbbreviations, formControl.options),
    ),
    ...ternary(
      equal(name, 'companyName'),
      {
        innerRef: ref,
        isLoading: isSearchingCompany,
        options: [...companyList] || [],
        isClearable: true,
        onInputChange: handleSearch,
        value: contactType,
        components: {
          MenuList: (props, ...args) =>
            SelectMenuButton(
              {
                ...props,
                onClick: addNewCompany,
                companyName,
              },
              ...args,
            ),
          Option: (args) =>
            CustomOption({ handleOpenEditOptionDialog, ...args }),

          DropdownIndicator: (args) =>
            CustomDropdownIndicator({ handleToggleMenuOption, ...args }),
        },
        onFocus: handleOpenMenu,
        onBlur: handleCloseMenu,
        onChange: handelInputChange,
        menuIsOpen,
      },
      {},
    ),
    disabled:
      hasOnlyViewPermission ||
      formControl.disabled ||
      (equal(name, 'companyName') && !!contactTypeToEdit),
  })

  let timer = null
  const handleSearch = (newValue) => {
    setCompanyName(newValue)
    clearTimeout(timer)
    timer = setTimeout(() => {
      dispatch(searchCompany(newValue))
    }, 300)
  }

  const editCompanyActions = (
    <DKTButton
      variant="contained"
      disableElevation
      onClick={handleEditCompanyName}
      sx={{ marginLeft: 'auto', marginRight: '16px', marginTop: '40px' }}
      disabled={!values.editCompanyName || !values?.isCompanyNameUpdateWarning}
    >
      Update
    </DKTButton>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseCreateContact = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }
  const addContactTypeFields = renderFormFields({
    values,
    errors,
    formFields,
    handleChange,
    customFormControlFields,
  })
  const editContactTypeFields = renderFormFields({
    formFields: editCompanyNameFormFields,
    values,
    errors,
    handleChange,
  })

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseCreateContact}
        title="Add/Edit Contact Type"
        actions={actions}
      >
        {equal(isSearchingCompany, true) ? (
          <Stack alignItems="center" justifyContent="center">
            <DKTCircularProgress />
          </Stack>
        ) : equal(isSearchingCompany, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Company name data. Please
              try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <DKTForm autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              {addContactTypeFields}
            </Grid>
          </DKTForm>
        )}
      </DKTDialog>
      <DKTDialog
        open={isOpenEditDialog}
        handleClose={handelCloseEditDialog}
        title="Edit Company Name"
        actions={editCompanyActions}
        className={classes.updateNameConfirmation}
      >
        <DKTForm autoComplete="off">{editContactTypeFields}</DKTForm>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isUnSavedChanges} onSave={handleSave} />
    </>
  )
}

export default CreateContact
