import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, length, lt, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  ADD_PROJECT_DIRECTORY_SUCCESS,
  CREATE_PROJECT_DIRECTORY_FAILED,
  CREATE_PROJECT_DIRECTORY_REQUESTED,
  CREATE_PROJECT_DIRECTORY_SUCCESS,
  DELETE_PROJECT_DIRECTORY_CONTACT,
  DELETE_PROJECT_DIRECTORY_FAILED,
  DELETE_PROJECT_DIRECTORY_REQUESTED,
  DELETE_PROJECT_DIRECTORY_SUCCESS,
  FETCH_PROJECT_DIRECTORY_FAILED,
  FETCH_PROJECT_DIRECTORY_LIST_FAILED,
  FETCH_PROJECT_DIRECTORY_LIST_REQUESTED,
  FETCH_PROJECT_DIRECTORY_LIST_SUCCESS,
  FETCH_PROJECT_DIRECTORY_MAPPING_FAILED,
  FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED,
  FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS,
  FETCH_PROJECT_DIRECTORY_REQUESTED,
  FETCH_PROJECT_DIRECTORY_SUCCESS,
  RESET_CURRENT_PROJECT_DIRECTORY,
  SAVE_CONTACT_TYPE_FAILED,
  SAVE_CONTACT_TYPE_REQUESTED,
  SAVE_CONTACT_TYPE_SUCCESS,
  SAVE_PROJECT_DIRECTORY_FAILED,
  SAVE_PROJECT_DIRECTORY_REQUESTED,
  SAVE_PROJECT_DIRECTORY_SUCCESS,
  SEARCH_COMPANY_FAILED,
  SEARCH_COMPANY_REQUESTED,
  SEARCH_COMPANY_SUCCESS,
  SET_PROJECT_DIRECTORY_ID,
  SORT_CONTACT_TYPE_SUCCESS,
  UPDATE_COMPANY_NAME_FAILED,
  UPDATE_COMPANY_NAME_REQUESTED,
  UPDATE_COMPANY_NAME_SUCCESS,
} from '../constants/projectDirectory'
import { updateSaveStatusAction } from './confirmation'

let timer = null
const UNIQUE_CODE_PREFIX = 'PD'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

const fetchProjectDirectoryRequested = () => ({
  type: FETCH_PROJECT_DIRECTORY_REQUESTED,
})
const fetchProjectDirectorySuccess = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_SUCCESS,
  payload,
})
const fetchProjectDirectoryFailed = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_FAILED,
  payload,
})

export const getProjectDirectory = (projectDirectoryId) => async (dispatch) => {
  dispatch(fetchProjectDirectoryRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.projectDirectory}${projectDirectoryId}`,
  })
  if (data) {
    dispatch(fetchProjectDirectorySuccess(data))
  }
  if (error) {
    dispatch(fetchProjectDirectoryFailed(error.response))
  }
}

const fetchProjectDirectoryListRequested = () => ({
  type: FETCH_PROJECT_DIRECTORY_LIST_REQUESTED,
})
const fetchProjectDirectoryListSuccess = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_LIST_SUCCESS,
  payload,
})
const fetchProjectDirectoryListFailed = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_LIST_FAILED,
  payload,
})

export const getProjectDirectoryList = (projectId) => async (dispatch) => {
  dispatch(fetchProjectDirectoryListRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.projectDirectory}?projectId=${projectId}`,
  })
  if (data) {
    const modifiedData = data?.map(({ id, uniqueCode }) => ({ id, uniqueCode }))
    dispatch(fetchProjectDirectoryListSuccess(modifiedData))
  }
  if (error) {
    dispatch(fetchProjectDirectoryListFailed(error.response))
  }
}

const createProjectDirectoryRequested = () => ({
  type: CREATE_PROJECT_DIRECTORY_REQUESTED,
})
const createProjectDirectorySuccess = (payload) => ({
  type: CREATE_PROJECT_DIRECTORY_SUCCESS,
  payload,
})
const createProjectDirectoryFailed = (payload) => ({
  type: CREATE_PROJECT_DIRECTORY_FAILED,
  payload,
})

export const createProjectDirectory =
  (payload, project, showToastMessage) => async (dispatch, getState) => {
    const { projectDirectory, projectDirectoryList, projectDirectoryId } =
      getState().projectDirectory
    dispatch(createProjectDirectoryRequested())
    const { uniqueCode } =
      projectDirectoryList?.find((projectDirectory) =>
        equal(projectDirectory.id, +projectDirectoryId),
      ) || {}
    const { data, error } = await api({
      method: method.post,
      endPoint: `${apiEndPoints.projectDirectory}?projectId=${project}`,
      data: {
        ...payload,
        project,
        uniqueCode:
          uniqueCode ||
          getUniqueCode(
            ternary(projectDirectoryList, length(projectDirectoryList) + 1, 1),
          ),
        contact: projectDirectory?.contact?.map(({ id }) => id) || [],
      },
      showToastMessage,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        createProjectDirectorySuccess({
          projectDirectoryList: projectDirectoryList?.map((projectDirectory) =>
            ternary(
              equal(projectDirectory.uniqueCode, uniqueCode),
              data,
              projectDirectory,
            ),
          ),
          projectDirectory: data,
        }),
      )
      dispatch(setProjectDirectoryId(data.id, false))
      dispatch(getProjectDirectoryMapping(project))
    }
    if (error) {
      dispatch(createProjectDirectoryFailed(error.response.data))
    }
  }

const saveProjectDirectoryRequested = () => ({
  type: SAVE_PROJECT_DIRECTORY_REQUESTED,
})
const saveProjectDirectorySuccess = (payload) => ({
  type: SAVE_PROJECT_DIRECTORY_SUCCESS,
  payload,
})
const saveProjectDirectoryFailed = (payload) => ({
  type: SAVE_PROJECT_DIRECTORY_FAILED,
  payload,
})

export const saveProjectDirectory =
  (payload, projectDirectoryId, project, showToastMessage) =>
  async (dispatch, getState) => {
    dispatch(saveProjectDirectoryRequested())
    const { projectDirectory } = getState().projectDirectory
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.projectDirectory}${projectDirectoryId}/?projectId=${project}`,
      data: {
        ...payload,
        contact: projectDirectory?.contact?.map(({ id }) => id),
        project,
      },
      showToastMessage,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(getProjectDirectoryMapping(project))
      dispatch(saveProjectDirectorySuccess(data))
    }
    if (error) {
      dispatch(saveProjectDirectoryFailed(error.response.data))
    }
  }

const addProjectDirectorySuccess = (payload) => ({
  type: ADD_PROJECT_DIRECTORY_SUCCESS,
  payload,
})

export const addProjectDirectory = () => (dispatch, getState) => {
  const { projectDirectoryList } = getState().projectDirectory
  const id = new Date().getTime()
  const sortedProjectDirectoryList = projectDirectoryList?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexProjectDirectory = sortedProjectDirectoryList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addProjectDirectorySuccess({
      projectDirectoryList: [
        ...(projectDirectoryList || []),
        {
          id,
          isNewProjectDirectory: true,
          uniqueCode: getUniqueCode(
            ternary(
              lastIndexProjectDirectory,
              +lastIndexProjectDirectory + 1,
              '1',
            ),
          ),
        },
      ],
      projectDirectory: null,
    }),
  )
  dispatch(setProjectDirectoryId(id, true))
}

export const setProjectDirectoryId = (
  projectDirectoryId,
  isNewProjectDirectory,
) => ({
  type: SET_PROJECT_DIRECTORY_ID,
  payload: { projectDirectoryId, isNewProjectDirectory },
})

const fetchProjectDirectoryMappingRequested = () => ({
  type: FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED,
})
const fetchProjectDirectoryMappingSuccess = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS,
  payload,
})
const fetchProjectDirectoryMappingFailed = (payload) => ({
  type: FETCH_PROJECT_DIRECTORY_MAPPING_FAILED,
  payload,
})

export const getProjectDirectoryMapping =
  (projectId) => async (dispatch, getState) => {
    const { projectDirectory, isNewProjectDirectory } =
      getState().projectDirectory
    dispatch(fetchProjectDirectoryMappingRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.projectDirectory}mapping/${projectId}/${ternary(
        projectDirectory?.uniqueCode && !isNewProjectDirectory,
        `?uniqueCode=${projectDirectory?.uniqueCode}`,
        '',
      )}`,
    })
    if (data) {
      const dataWithUpdatedKeys = data
        ?.sort((a, b) => (a?.uniqueCode > b?.uniqueCode ? 1 : -1))
        ?.map(({ uniqueCode, id } = {}) => ({
          label: uniqueCode,
          value: id.toString(),
        }))
      dispatch(fetchProjectDirectoryMappingSuccess(dataWithUpdatedKeys))
      const toast = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          if (!dataWithUpdatedKeys?.length && isNewProjectDirectory) {
            showToast(
              'Complete ‘Owner-Contractor’ prior to entering data on ‘Project Directory’ page. ',
              'error',
              'top-right',
            )
          }
        }, 2000)
      }
      toast()
    }
    if (error) {
      dispatch(fetchProjectDirectoryMappingFailed(error.response.data))
    }
  }

// Contact Type
const saveContactTypeRequested = () => ({
  type: SAVE_CONTACT_TYPE_REQUESTED,
})
const saveContactTypeSuccess = (payload) => ({
  type: SAVE_CONTACT_TYPE_SUCCESS,
  payload,
})
const saveContactTypeFailed = (payload) => ({
  type: SAVE_CONTACT_TYPE_FAILED,
  payload,
})

export const saveContactType =
  (payload, contactTypeId, projectId, { onSuccess = () => {} } = {}) =>
  async (dispatch, getState) => {
    dispatch(saveContactTypeRequested())
    const { data, error } = await api({
      method: ternary(contactTypeId, method.patch, method.post),
      endPoint: ternary(
        contactTypeId,
        `${apiEndPoints.projectContact}${contactTypeId}/?projectId=${projectId}`,
        `${apiEndPoints.projectContact}?projectId=${projectId}`,
      ),
      data: payload,
      showToastMessage: true,
      toastMessage: ternary(
        contactTypeId,
        'Contact type updated successfully!',
        'Contact type saved successfully!',
      ),
    })
    if (data) {
      const { projectDirectory = {} } = getState().projectDirectory
      const isIncludeInProjectDirectoryContacts =
        projectDirectory?.contact?.find(({ id }) => equal(id, contactTypeId))
      const contactTypeData = {
        ...projectDirectory,
        contact:
          contactTypeId && isIncludeInProjectDirectoryContacts
            ? projectDirectory?.contact?.map((contact) =>
                equal(contact.id, +contactTypeId)
                  ? { ...contact, ...data }
                  : contact,
              )
            : [
                ...(projectDirectory && projectDirectory.contact
                  ? projectDirectory.contact || []
                  : []),
                data,
              ],
      }
      setTimeout(() => {
        dispatch(updateSaveStatusAction(data, error))
      }, 700)
      dispatch(saveContactTypeSuccess(contactTypeData))
      dispatch(searchCompany(''))
      onSuccess()
    }
    if (error) {
      dispatch(saveContactTypeFailed(error.response.data))
    }
  }

const sortContactTypesSuccess = (payload) => ({
  type: SORT_CONTACT_TYPE_SUCCESS,
  payload,
})

export const sortContactTypes = (payload) => (dispatch, getState) => {
  const { projectDirectory = {} } = getState().projectDirectory
  dispatch(sortContactTypesSuccess({ ...projectDirectory, contact: payload }))
}

const searchCompanyRequested = () => ({
  type: SEARCH_COMPANY_REQUESTED,
})
const searchCompanySuccess = (payload) => ({
  type: SEARCH_COMPANY_SUCCESS,
  payload,
})
const searchCompanyFailed = (payload) => ({
  type: SEARCH_COMPANY_FAILED,
  payload,
})

export const searchCompany = (searchQuery, isPopulate) => async (dispatch) => {
  if (isPopulate) {
    dispatch(searchCompanyRequested())
  }
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.projectContactSearch}${ternary(
      searchQuery,
      `?fieldName=company&query=${searchQuery}`,
      '',
    )}`,
  })
  if (data) {
    const dataWithLabelValue = data.map((company) => ({
      label: company.companyName,
      value: company.id.toString(),
      city: company?.city,
      state: company?.state,
      contactType: company?.contactType,
    }))
    dispatch(searchCompanySuccess({ dataWithLabelValue, data }))
  }
  if (error) {
    dispatch(searchCompanyFailed(error.response))
  }
}

// Delete project directory
const deleteProjectDirectoryRequested = () => ({
  type: DELETE_PROJECT_DIRECTORY_REQUESTED,
})
export const deleteProjectDirectorySuccess =
  (payload) => (dispatch, getState) => {
    const { projectDirectoryList } = getState().projectDirectory
    const index = projectDirectoryList?.findIndex(({ id }) =>
      equal(id, payload?.projectDirectory[0]),
    )
    if (~index) {
      projectDirectoryList.splice(index, 1)
    }
    dispatch({
      type: DELETE_PROJECT_DIRECTORY_SUCCESS,
      payload: projectDirectoryList,
      activeProjectDirectory: projectDirectoryList[0]?.id,
      isNewProjectDirectory: projectDirectoryList[0]?.isNewProjectDirectory,
    })
  }
const deleteProjectDirectoryFailed = (payload) => ({
  type: DELETE_PROJECT_DIRECTORY_FAILED,
  payload,
})

export const deleteProjectDirectory =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteProjectDirectoryRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.deleteProjectDirectory}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Project directory deleted successfully!',
    })
    if (status) {
      handleClose()
      dispatch(deleteProjectDirectorySuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteProjectDirectoryFailed(error.response.data))
    }
  }

export const resetCurrentProjectDirectory = () => ({
  type: RESET_CURRENT_PROJECT_DIRECTORY,
})

// Update company  name of project directory
const updateCompanyNameRequested = () => ({
  type: UPDATE_COMPANY_NAME_REQUESTED,
})
const updateCompanyNameSuccess = (payload) => ({
  type: UPDATE_COMPANY_NAME_SUCCESS,
  payload,
})
const updateCompanyNameFailed = (payload) => ({
  type: UPDATE_COMPANY_NAME_FAILED,
  payload,
})

export const updateCompanyName =
  ({ payload, companyId }) =>
  async (dispatch, getState) => {
    const { searchResults } = getState().projectDirectory
    dispatch(updateCompanyNameRequested())
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.projectContactUpdate}${companyId}/?fieldName=company`,
      data: {
        ...payload,
      },
      showToastMessage: false,
      toastMessage: 'Saved successfully!',
    })
    if (data) {
      const clone = [...(searchResults || [])]
      const indexOfPayload = clone?.findIndex(({ value }) =>
        equal(+value, +companyId),
      )
      clone?.splice(indexOfPayload, 1, {
        label: data?.companyName,
        value: data?.id?.toString(),
      })
      dispatch(updateCompanyNameSuccess(clone))
      return
    }
    if (error) {
      dispatch(updateCompanyNameFailed(error.response.data))
    }
  }

// Delete project directory contact
export const deleteProjectDirectoryContact = (payload) => ({
  type: DELETE_PROJECT_DIRECTORY_CONTACT,
  payload,
})
