import { Box, FormHelperText, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  AccessibilityHelp,
  Autosave,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  Italic,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Underline,
  Undo,
  Mention,
  Alignment,
} from 'ckeditor5'
import { ternary } from '../Utils/javascript'

const editorConfig = {
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    Alignment,
    AccessibilityHelp,
    Autosave,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    Italic,
    List,
    ListProperties,
    Paragraph,
    SelectAll,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Underline,
    Undo,
    Mention,
  ],
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true,
    },
  },
  placeholder: 'Placeholder Text',
  fontColor: {
    colors: [
      {
        color: '#000000',
        label: 'Black',
      },
      {
        color: '#FF0000',
        label: 'Red',
      },
    ],
  },
}
export const useStyles = makeStyles(() => ({
  commentBox: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
      '& .ck-sticky-panel__content_sticky': {
        position: 'static !important',
      },
      '& .ck-sticky-panel__content': {
        border: 'none !important',
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '210px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '210px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
  },
}))

const DKTEditor = ({
  title,
  onChange,
  value,
  disabled,
  className,
  error = '',
  isRequired = false,
  configData = {},
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Box>
      {title && (
        <Stack direction="row" alignItems="end" justifyContent="space-between">
          <Typography
            variant="body2"
            color="gray.extraDark"
            sx={{ fontWeight: 'medium' }}
            mb={0.8}
          >
            {title}
            {isRequired && (
              <span
                aria-hidden="true"
                className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                style={{ color: 'red' }}
              >
                *
              </span>
            )}
          </Typography>
        </Stack>
      )}
      <Box
        className={`${classes.commentBox} ${className && className}`}
        style={ternary(
          disabled,
          {
            color: 'grey',
            pointerEvents: 'none',
            userSelect: 'none',
          },
          {},
        )}
      >
        <CKEditor
          editor={ClassicEditor}
          config={{ ...editorConfig, ...configData }}
          data={value || ''}
          onChange={onChange}
          {...rest}
        />
      </Box>
      {error && <FormHelperText error={!!error}>Required</FormHelperText>}
    </Box>
  )
}

export default DKTEditor
